$(document).on("turbo:load", () => {
  $("table.player_scenarios").on("click", ".preview_link", function (event) {
    var url = $(this).attr("href");

    window.open(
      url,
      "preview",
      "toolbar=no,scrollbars=no,resizable=no,top=500,left=500,width=302,height=590"
    );

    event.preventDefault();
  });
});
