import "@selectize/selectize/dist/js/selectize.min.js";
$(document).on("turbo:load", () => {
  if (!$(".statistics #spot_name").hasClass("selectized")) {
    $(".statistics #spot_name").selectize({
      onItemAdd: function (value) {
        if (value === "all") {
          this.clear();
          this.setValue("All spots");
        }
      },
      onChange: function (value) {
        if (value !== "") {
          this.removeOption("all");
        }
      },
      create: false,
    });
  }

  if (!$(".statistics #box_id").hasClass("selectized")) {
    $(".statistics #box_id").selectize({
      onItemAdd: function (value) {
        if (value === "all") {
          this.clear();
          this.setValue("All Boxes");
        }
      },
      onChange: function (value) {
        if (value !== "") {
          this.removeOption("all");
        }
      },
      create: false,
    });
  }

  // After the page is loaded or reloaded, ensure all selected items are trimmed
  $(".statistics #box_id").each(function () {
    const selectize = $(this)[0].selectize;
    if (selectize) {
      selectize.items.forEach((item) => {
        const selectedOption = selectize.options[item];
        if (selectedOption) {
          const trimmedText = selectedOption.text.split(" - ")[0];
          $(selectize.getItem(item)).text(trimmedText);
        }
      });
    }
  });

  if (!$(".box_list #s_name").hasClass("selectized")) {
    $(".box_list #s_name").selectize({
      onItemAdd: function (value) {
        if (value === "all") {
          this.clear();
          this.setValue("All spots");
        }
      },
      onChange: function (value) {
        if (value !== "") {
          this.removeOption("all");
        }
      },
      create: false,
    });
  }

  if (!$(".spot_list #b_id").hasClass("selectized")) {
    $(".spot_list #b_id").selectize({
      onItemAdd: function (value) {
        if (value === "all") {
          this.clear();
          this.setValue("All Boxes");
        }
      },
      onChange: function (value) {
        if (value !== "") {
          this.removeOption("all");
        }
      },
      create: false,
    });
  }

  // After the page is loaded or reloaded, ensure all selected items are trimmed
  $(".spot_list #b_id").each(function () {
    const selectize = $(this)[0].selectize;
    if (selectize) {
      selectize.items.forEach((item) => {
        const selectedOption = selectize.options[item];
        if (selectedOption) {
          const trimmedText = selectedOption.text.split(" - ")[0];
          $(selectize.getItem(item)).text(trimmedText);
        }
      });
    }
  });

  if (!$(".spot_list #category_name").hasClass("selectized")) {
    $(".spot_list #category_name").selectize({
      onItemAdd: function (value) {
        if (value === "all") {
          this.clear();
          this.setValue("All Categories");
        }
      },
      onChange: function (value) {
        if (value !== "") {
          this.removeOption("all");
        }
      },
      create: false,
    });
  }

  if (!$(".spot_list #sph1_code").hasClass("selectized")) {
    $(".spot_list #sph1_code").selectize({
      onItemAdd: function (value) {
        if (value === "all") {
          this.clear();
          this.setValue("All 3LC's");
        }
      },
      onChange: function (value) {
        if (value !== "") {
          this.removeOption("all");
        }
      },
      create: false,
    });
  }
});
