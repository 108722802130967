// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log("Vite ⚡️ Rails");

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log(
  "Visit the guide for more information: ",
  "https://vite-ruby.netlify.app/guide/rails"
);

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "@hotwired/turbo-rails";

import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();
application.debug = true;
const controllers = import.meta.glob("../controllers/**/*_controller.ts", {
  eager: true,
});
registerControllers(application, controllers);

import "~/javascript/rails-globals";
import "~/javascript/bootstrap";
import "~/javascript/refile";

import "~/javascript/irreg_steles";

import "~/javascript/media";
import "~/javascript/spot_files";
import "~/javascript/spots";
import "chartkick/chart.js";
import "~/javascript/selectize";
import "~/javascript/tickertext";
